import {
	Alert,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormHelperText,
	TextField,
	TextareaAutosize,
	Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import CellSpecAutoComplete from 'components/cell-spec-select/CellSpecAutoComplete';
import GroupSelection from 'components/aging-group-select/GroupSelection';
import { UserGroupBaseType } from 'api/api.types';
import { UserGroupSingleSelect } from 'components/user-group-selection/UserGroupSingleSelect';
import getErrorMessage from 'util/handle-catch-error';
import { toCamelCase } from 'util/string-transform';
import { usePutWorkOrder } from '../api/use-put-work-order';

const defaultBoxSx = {
	display: 'flex',
	pb: 2,
	justifyContent: 'flex-start',
	alignItems: 'center',
	gap: 1,
	width: '100%',
	'> .MuiTypography-root': { minWidth: '160px', maxWidth: '160px' },
	'> .MuiFormControl-root': {
		flex: 1,
	},
};

const style = { border: '1px solid grey', padding: '4px' };

interface FormStateType {
	cellSpec: { id: string } | null;
	groupId: string;
	workOrders: string;
	phases: string;
	userGroup: UserGroupBaseType | null;
	elementIdentifier: string;
	link: string;
}

const throwError = (field: string, line: string, msg: string) => {
	throw Error(`Error on ${field} ${line}: ${msg}`);
};
const validateHeader = (field, lineNumber, cells, allowedKeys) => {
	allowedKeys.forEach((expectedKey, index) => {
		if (cells[index] !== expectedKey) {
			throwError(field, 'Header row', `Expected key '${expectedKey}' with the exact case: Found key '${cells[index]}'`);
		}
	});
	if (cells.length > allowedKeys.length) {
		const extraKeys = cells.slice(allowedKeys.length, cells.length).join(', ');
		throwError(field, 'Header row', `Extra key(s) in header: ${extraKeys}`);
	}
};
const validateRow = (field, lineNumber, cells, headers) => {
	if (cells.length < headers.length) {
		throwError(
			field,
			`Line ${lineNumber + 1}`,
			`Missing value(s) for: ${headers.slice(cells.length, headers.length).join(', ')}`
		);
	}
	if (cells.length > headers.length) {
		const extraValues = cells.slice(headers.length, cells.length).join(', ');
		throwError(field, `Line ${lineNumber + 1}`, `Extra value(s): ${extraValues}`);
	}
};

const allowedWorkOrderKeys = [
	'Protocol Block',
	'Protocol Block Description',
	'Protocol Step',
	'Experiment ID',
	'Temperature (ºC)',
	'Duration (Days)',
];
const validateWorkOrders = (workOrders: string): string[] => {
	const orders = workOrders.split('\n');
	const ordersPayload = [] as any[];
	let hasHeader = false;
	for (let i = 0; i < orders.length; i++) {
		const cells = orders[i].split('\t');
		if (cells.every((val) => val === '')) {
			continue;
		}
		if (!hasHeader) {
			validateHeader('WorkOrders', i, cells, allowedWorkOrderKeys);
			hasHeader = true;
		} else {
			validateRow('WorkOrders', i, cells, allowedWorkOrderKeys);
			const order = {};
			cells.forEach((val, index) => {
				const key = toCamelCase(allowedWorkOrderKeys[index].replace(/\s*\(.*?\)/, ''));
				order[key] = isNaN(Number(val)) ? val : Number(val);
			});
			ordersPayload.push(order);
		}
	}

	return ordersPayload;
};

const allowedPhaseKeys = ['Protocol Block ID', 'Protocol Block Description', 'Model Phase'];
const validatePhases = (phases: string): string[] => {
	const phaseRows = phases.split('\n');
	const phasePayload = [] as any[];
	let hasHeader = false;
	for (let i = 0; i < phaseRows.length; i++) {
		const cells = phaseRows[i].split('\t');
		if (cells.every((val) => val === '')) {
			continue;
		}
		if (!hasHeader) {
			validateHeader('Phases', i, cells, allowedPhaseKeys);
			hasHeader = true;
		} else {
			validateRow('Phases', i, cells, allowedPhaseKeys);
			if (!Number.isInteger(parseFloat(cells[2]))) {
				throwError('Phases', `Line ${i + 1}`, `'Model Phase' must be an integer`);
			}
			const phase = {};
			cells.forEach((val, index) => {
				const key = toCamelCase(allowedPhaseKeys[index]);
				phase[key] = isNaN(Number(val)) ? val : Number(val);
			});
			phasePayload.push(phase);
		}
	}
	return phasePayload;
};

export function CreateWorkOrderForm({ open, onClose }) {
	const putWorkOrder = usePutWorkOrder();
	const {
		control,
		formState: { errors },
		setError,
		setValue,
		clearErrors,
		getValues,
		watch,
		handleSubmit,
		reset,
	} = useForm<FormStateType>({
		mode: 'onChange',
		defaultValues: {
			cellSpec: null,
			groupId: '',
			workOrders: '',
			phases: '',
			userGroup: null,
			elementIdentifier: '',
			link: '',
		},
		values: {
			cellSpec: null,
			groupId: '',
			workOrders: '',
			phases: '',
			userGroup: null,
			elementIdentifier: '',
			link: '',
		},
	});
	const cellSpec = watch('cellSpec');

	const submitWorkOrder = async () => {
		clearErrors('root.serverError');
		try {
			const { cellSpec, groupId, workOrders, phases, userGroup, elementIdentifier, link } = getValues();
			await putWorkOrder.mutateAsync({
				cellSpecId: cellSpec?.id,
				groupId,
				workOrders: validateWorkOrders(workOrders),
				phases: validatePhases(phases),
				userGroupId: userGroup?.id,
				elementIdentifier,
				spreadsheetLink: link,
			} as any);
		} catch (err: unknown) {
			const message = getErrorMessage(err);
			setError('root.serverError', {
				message: message,
			});
		}
	};

	const handleClose = () => {
		if (putWorkOrder.isSuccess) {
			reset();
			putWorkOrder.reset();
		}
		onClose();
	};

	return (
		<>
			<Dialog open={open} fullWidth maxWidth="lg" onClose={handleClose} keepMounted>
				<DialogTitle>Create Work Order</DialogTitle>
				<DialogContent>
					<Box sx={{ mb: 2 }}>
						<Typography>
							Please choose the 'Cell Spec (Device Prefix)' and 'Group' that the work orders should be assigned to.
						</Typography>
						<Box sx={defaultBoxSx}>
							<Typography>Cell Spec</Typography>
							<Controller
								control={control}
								name="cellSpec"
								rules={{
									required: 'cellSpec (devicePrefix) is required',
								}}
								render={({ field: { name, onChange, value }, fieldState: { error } }) => (
									<FormControl error={error !== undefined}>
										<CellSpecAutoComplete
											name={name}
											value={value}
											onChange={(val) => {
												onChange(val);
												setValue('groupId', '');
											}}
										/>
										{error && <FormHelperText>{error?.message}</FormHelperText>}
									</FormControl>
								)}
							/>
						</Box>
						<Box sx={defaultBoxSx}>
							<Typography>Group</Typography>
							<Controller
								control={control}
								name="groupId"
								rules={{
									required: 'group is required',
								}}
								render={({ field: { name, onChange, value }, fieldState: { error } }) => (
									<FormControl error={error !== undefined}>
										<GroupSelection
											name={name}
											value={value}
											onChange={onChange}
											cellSpecId={cellSpec?.id}
											disabled={cellSpec === null}
										/>
										{error && <FormHelperText>{error?.message}</FormHelperText>}
									</FormControl>
								)}
							/>
						</Box>
					</Box>
					<Typography>
						Please enter the URL to a group protocol sheet (sheet name follows the pattern
						"DevicePrefix_GroupNumber_Protocol", e.g.,{' '}
						<a
							href="https://docs.google.com/spreadsheets/d/1JRWON2Joh5Abbfxyv6H5d3HgeS60J4v3_OiREp6-EYk/edit?gid=1339254514#gid=1339254514"
							target="_blank"
							rel="noreferrer">
							"DEVICE_Group1_Protocol"
						</a>
						) from a "Test Plan" Google Sheet.
					</Typography>
					<Box sx={defaultBoxSx}>
						<Typography>Spreadsheet Link</Typography>
						<Controller
							control={control}
							name="link"
							rules={{ required: 'spreadsheet link is required' }}
							render={({ field: { name, onChange, value }, fieldState: { error } }) => (
								<FormControl error={error !== undefined}>
									<TextField name={name} value={value} onChange={onChange} />
									{error && <FormHelperText>{error?.message}</FormHelperText>}
								</FormControl>
							)}
						/>
					</Box>
					<Box sx={{ mb: 2 }}>
						<Typography>Work Orders</Typography>
						<Typography>
							Please copy and paste from the group protocol sheet (sheet name follows the pattern
							"DevicePrefix_GroupNumber_Protocol", e.g.,{' '}
							<a
								href="https://docs.google.com/spreadsheets/d/1JRWON2Joh5Abbfxyv6H5d3HgeS60J4v3_OiREp6-EYk/edit?gid=1339254514#gid=1339254514"
								target="_blank"
								rel="noreferrer">
								"DEVICE_Group1_Protocol"
							</a>
							) from "Test Plan" Google Sheet. Example:
						</Typography>
						<table>
							<thead>
								<tr>
									<th style={style}>Protocol Block</th>
									<th style={style}>Protocol Block Description</th>
									<th style={style}>Protocol Step</th>
									<th style={style}>Experiment ID</th>
									<th style={style}>Temperature (ºC)</th>
									<th style={style}>Duration (Days)</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td style={style}>1</td>
									<td style={style}>Break-In</td>
									<td style={style}>1</td>
									<td style={style}>[Active] IWLCA_WORK_BREAKIN_B2</td>
									<td style={style}>25</td>
									<td style={style}>4.68</td>
								</tr>
							</tbody>
						</table>
						<Typography>
							All of the above columns must be present, separated by the tab character, and include the header row.
						</Typography>
						<Box>
							<Controller
								control={control}
								name="workOrders"
								rules={{ required: 'work orders are required' }}
								render={({ field: { name, onChange, value }, fieldState: { error } }) => (
									<FormControl sx={{ width: '100%' }} error={error !== undefined}>
										<TextareaAutosize id={name} name={name} value={value} onChange={onChange} minRows={5} />
										{error && <FormHelperText>{error?.message}</FormHelperText>}
									</FormControl>
								)}
							/>
						</Box>
					</Box>
					<Box sx={{ mb: 2 }}>
						<Typography>Phases</Typography>
						<Typography>
							Please copy and paste from the{' '}
							<a
								href="https://docs.google.com/spreadsheets/d/1JRWON2Joh5Abbfxyv6H5d3HgeS60J4v3_OiREp6-EYk/edit?gid=620281461#gid=620281461"
								target="_blank"
								rel="noreferrer">
								"Test_Matrix"
							</a>{' '}
							sheet from "Test Plan" Google Sheet. Example:
						</Typography>
						<table>
							<thead>
								<tr>
									<th style={style}>Protocol Block ID</th>
									<th style={style}>Protocol Block Description</th>
									<th style={style}>Model Phase</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td style={style}>1</td>
									<td style={style}>Break-In</td>
									<td style={style}>0</td>
								</tr>
							</tbody>
						</table>
						<Typography>
							All of the above columns must be present, separated by the tab character, and include the header row.
						</Typography>
						<Box>
							<Controller
								control={control}
								name="phases"
								rules={{ required: 'phases are required' }}
								render={({ field: { name, onChange, value }, fieldState: { error } }) => (
									<FormControl sx={{ width: '100%' }} error={error !== undefined}>
										<TextareaAutosize id={name} name={name} value={value} onChange={onChange} minRows={5} />
										{error && <FormHelperText>{error?.message}</FormHelperText>}
									</FormControl>
								)}
							/>
						</Box>
					</Box>
					<Typography>Created work orders will be visible to the selected user group.</Typography>
					<Box sx={defaultBoxSx}>
						<Typography>User Group</Typography>
						<Controller
							control={control}
							name="userGroup"
							rules={{ required: 'userGroup is required' }}
							render={({ field: { name, onChange, value }, fieldState: { error } }) => (
								<FormControl error={error !== undefined}>
									<UserGroupSingleSelect selectedUserGroup={value} setSelectedUserGroup={onChange} />
									{error && <FormHelperText>{error?.message}</FormHelperText>}
								</FormControl>
							)}
						/>
					</Box>
					<Typography>Only required for Element; leave it blank for other testing partners.</Typography>
					<Box sx={defaultBoxSx}>
						<Typography>Element identifier</Typography>
						<Controller
							control={control}
							name="elementIdentifier"
							render={({ field: { name, onChange, value }, fieldState: { error } }) => (
								<FormControl>
									<TextField name={name} value={value} onChange={onChange} />
								</FormControl>
							)}
						/>
					</Box>
				</DialogContent>
				<DialogActions>
					<Box
						sx={{ textOverflow: 'ellipsis', overflow: 'hidden', height: '100%', width: 'calc(100% - 120px)', flex: 1 }}>
						{putWorkOrder.isSuccess && (
							<Alert severity="success" sx={{ width: '100%' }}>
								Successfully created work order(s)!
							</Alert>
						)}
						{errors.root?.serverError && <Typography color="error">{errors.root?.serverError?.message}</Typography>}
					</Box>
					<Button onClick={handleClose}>Cancel</Button>
					<LoadingButton
						type="submit"
						onClick={handleSubmit(submitWorkOrder)}
						loading={putWorkOrder.isLoading}
						disabled={putWorkOrder.isSuccess}>
						Submit
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</>
	);
}
